@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root{
  height: 100%;
  width: 100%;
  background-color: white;
  color:black;
  overflow-y:auto;
  font-family: 'Poppins',sans-serif;
}
* {
  box-sizing: border-box;
}
.transform-component-module_wrapper__1_Fgj {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;

}
.transform-component-module_content__2jYgh {
  height: 100% !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}